import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, UrlHandlingStrategy, UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {
  UpgradeModule, downgradeInjectable, downgradeComponent
} from '@angular/upgrade/static';
import {
  NgbTooltipModule, NgbDropdownModule, NgbCollapseModule
} from '@ng-bootstrap/ng-bootstrap';

import {
  DatepickerModule, DatepickerConfig,
  DateAdapter, DateNativeAdapter, DateNativeUTCAdapter,
} from 'core/datepicker';

import { GlobalErrorHandler } from './main/config/app.errorHandler';
import { AuthInterceptor } from './main/config/http.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';

import { Navbar } from './main/navbar/navbar';
import { NavbarControls } from './main/controls/controls';
import { NavbarUnitate } from './main/navbar/navbar.unitate';
import { ScrollToTop } from './main/scrollTop/scrollTop';

import * as ng1Services from './services';
import {
  DataService, ConfigService, UserService,
  LogService, ReportService, WebSocketService, ValidationService,
  DialogService, FileService, CursValutarService, ConfigServiceNew,
} from 'core';
import { FeedbackModal } from './main/navbar/navbar.feedback';
import { ControlsContainer } from './main/controls/controls.container';

declare var angular: any;
angular.module('sigma').service('user', downgradeInjectable(UserService));
angular.module('sigma').service('config', downgradeInjectable(ConfigService));
angular.module('sigma').service('data', downgradeInjectable(DataService));
angular.module('sigma').service('log', downgradeInjectable(LogService));
angular.module('sigma').service('report', downgradeInjectable(ReportService));
angular.module('sigma').service('validation', downgradeInjectable(ValidationService));
angular.module('sigma').service('ws', downgradeInjectable(WebSocketService));
angular.module('sigma').factory('ng2Router', downgradeInjectable(Router))

// angular.module('sigma').service('cursValutar', downgradeInjectable(CursValutarService));
// angular.module('sigma').service('dialog', downgradeInjectable(DialogService));
// angular.module('sigma').service('file', downgradeInjectable(FileService));
// angular.module('sigma').directive('scrollTop', downgradeComponent({ component: ScrollToTop }));

export class CustomHandlingStrategy implements UrlHandlingStrategy {
  merge(url, whole) {
    console.log('ng2 merge, url', url.toString());
    console.log('ng2 merge, whole url', whole.toString());
    return url;
  }
  extract(url) {
    console.log('ng2 extract url', url.toString());
    return url//.toString()
  }
  shouldProcessUrl(url) {
    url = url.toString();
    console.log('ng2 shouldProcessUrl', `"${url}"`, url.includes("ng/"));
    const shouldProcess = url == "" || url == "/" || url.includes("ng/") || url.includes("login") || url == "/RouterOutletEmptyContent";
    console.log("angular 2 routing", { url, shouldProcess });
    return shouldProcess;
  }
}

export class CustomeUrlSerializer extends DefaultUrlSerializer {
  private url: string;

  override parse(url: any): UrlTree {
    const path = window.location.pathname;
    // TODO: check if also need to reset on ng2 routes
    if (path == '/home' || path == '/login') { this.url = '' }
    return super.parse(url);
  }
  override serialize(tree: UrlTree): any {
    let path = this._withTrailingSlash(super.serialize(tree));
    if (path.indexOf('/admin/Stop/') > -1) { return path }
    return path.length > 1 && this.url && !this.url.includes('ng/') ? path + this.url.split(path).pop() : path;
  }

  private _withTrailingSlash(url: string): string {
    const splitOn = url.indexOf('?') > - 1 ? '?' : '#';
    const pathArr = url.split(splitOn);

    if (!pathArr[0].endsWith('/')) {
      const fileName: string = url.substring(url.lastIndexOf('/') + 1);
      if (fileName.indexOf('.') === -1) { pathArr[0] += '/' }
    }
    return pathArr.join(splitOn);
  }
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent, ScrollToTop, FeedbackModal,
    Navbar, NavbarUnitate, NavbarControls, ControlsContainer,
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, FormsModule,
    UpgradeModule, //will be removed when dropping ng1
    AppRoutingModule, CoreModule,
    NgbDropdownModule, NgbTooltipModule, NgbCollapseModule,
    DatepickerModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: UrlHandlingStrategy, useClass: CustomHandlingStrategy }, // to be removed when dropping ng1
    { provide: UrlSerializer, useClass: CustomeUrlSerializer }, // to be removed when dropping ng1
    // { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
    { provide: DateAdapter, useClass: DateNativeAdapter },

    // ng1 services
    { provide: '$state', useFactory: ($injector: any) => $injector.get('$state'), deps: ['$injector'] },
    { provide: ng1Services.AlertService, useFactory: (injector) => { return injector.get('alert') }, deps: ['$injector'] },
    provideHttpClient(withInterceptorsFromDi()),

    // { provide: ng1Services.settings, useFactory: (injector) => { return injector.get('settings') }, deps: ['$injector'] },
    // { provide: ng1Services.DataService, useFactory: (injector) => { return injector.get('data') }, deps: ['$injector'] },
    // { provide: ng1Services.UserService, useFactory: (injector) => { return injector.get('user') }, deps: ['$injector'] },
    // { provide: ng1Services.DialogService, useFactory: (injector) => { return injector.get('dialog') }, deps: ['$injector'] },
    // { provide: ng1Services.ConfigService, useFactory: (injector) => { return injector.get('config') }, deps: ['$injector'] },
    // { provide: ng1Services.CursValutarService, useFactory: (injector) => { return injector.get('cursValutar') }, deps: ['$injector'] },
    // { provide: ng1Services.EmailService, useFactory: (injector) => { return injector.get('email') }, deps: ['$injector'] },
    // { provide: ng1Services.FileService, useFactory: (injector) => { return injector.get('file') }, deps: ['$injector'] },
    // { provide: ng1Services.LogService, useFactory: (injector) => { return injector.get('log') }, deps: ['$injector'] },
    // { provide: ng1Services.ReportService, useFactory: (injector) => { return injector.get('report') }, deps: ['$injector'] },
    // { provide: ng1Services.ValidationService, useFactory: (injector) => { return injector.get('validation') }, deps: ['$injector'] },
    // { provide: ng1Services.WebSocketService, useFactory: (injector) => { return injector.get('ws') }, deps: ['$injector'] },
    // ng2 providers
  ],
})
export class AppModule {
  constructor(config: DatepickerConfig, private upgrade: UpgradeModule) {
    // customize default values of datepickers used by this component tree
    // config.format = 'dd.MM.yyyy';
    // config.minDate = {year: 1900, month: 1, day: 1};
    // config.maxDate = {year: 2099, month: 12, day: 31};
    // setting datepicker popup to open above the input
    // config.placement = ['top-left', 'top-right'];

    this.upgrade.bootstrap(document.documentElement, ['sigma'])
  }
}
