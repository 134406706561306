<nav id="controlsNavbar" *ngIf="user.isAuthenticated" class="navbar navbar-expand-lg py-1 border-0"
    [ngClass]="[style.css.controlsType, style.css.controlsBg, style.css.controlsPosition]"><!--TODO: remove border-0 ng1>ng2-->
    <div class="container-fluid">
        <form class="form-inline w-xs-80 me-auto" role="search" (submit)="onSearch($event)" *ngIf="!isSearchHidden">
            <div class="input-group">
                <!--ng-model-options="{ debounce: {'default': 500} }"-->
                <input type="text" class="form-control" [ngClass]="controlSize('form-control')" autocomplete="off"
                    [placeholder]="searchPlaceholder || 'Cautati...'"
                    [(ngModel)]="controls.searchText" name="search"
                    (keypress)="onKeyPress($event)" />
                <button type="submit" class="btn btn-primary" [ngClass]="controlSize('btn')">
                    <i class="far fa-1x" [ngClass]="searchIcon || 'fa-search'"></i>
                </button>
            </div>
        </form>

        <button type="button" class="btn btn-primary" *ngIf="style.isMobile;else normalBtn"
            [ngClass]="controlSize('btn')" (click)="openBtnMenu()">
            <i class="fa fa-bars"></i>
        </button>

        <!-- <ng-template #normalBtn>
            <route-controls-btn>&nbsp;<i class="far fa-minus fa-rotate-90"></i></route-controls-btn>
        </ng-template> -->
        <ng-template #tooltipContent let-msg="msg">{{msg}}</ng-template>

        <ng-template #normalBtn>
            <div *ngFor="let btn of getButtons()">
                <!--divider-->
                <span *ngIf="btn.type === 'div'" role="separator">
                    &nbsp;<i class="far fa-minus fa-rotate-90"></i>
                </span>

                <!--button-->
                <button type="button" class="btn ms-2" [ngClass]="controlSize('btn', btn)"
                    *ngIf="btn.type === 'cmd' && !btn.btns && (!btn.splitCaret || (btn.splitCaret && (!btn.btns || (btn.btns && !btn.btns.length)))) && isVisible(btn)"
                    [disabled]="isDisabled(btn)" (click)="onClick(btn)">
                    <!--[ngbTooltip]="tooltipContent" triggers="manual" #tool="ngbTooltip"
                    (mouseenter)="setTooltip(tool, btn)" (mouseleave)="tool.close()" -->
                    <i class="far fa-1x" [ngClass]="btn.icon || 'fa-file-o'"></i>
                    <span class="hidden-xs">&nbsp;{{btn.caption}}</span>
                </button>

                <!--dropdown split caret-->
                <div class="btn-group ms-2"
                    *ngIf="btn.type === 'cmd' && btn.splitCaret && btn.btns && btn.btns.length > 0 && isVisible(btn)">
                    <button type="button" class="btn" [ngClass]="controlSize('btn', btn)"
                        [disabled]="isDisabled(btn)" (click)="onClick(btn)" [attr.title]="btn.tooltip">
                        <!-- [ngbTooltip]="tooltipContent" triggers="manual" #tool="ngbTooltip"
                        (mouseenter)="setTooltip(tool, btn)" (mouseleave)="tool.close()"> -->
                        <i class="far fa-1x" [ngClass]="btn.icon"></i>
                        <span class="hidden-xs">&nbsp;{{btn.caption}}</span>
                    </button>
                    <div class="btn-group" role="group" [ngClass]="controlSize('btn-group')"
                        *ngIf="btn.type === 'cmd' && btn.btns && btn.btns.length > 0"
                        display="dynamic" placement="bottom-right" ngbDropdown>

                        <button type="button" class="btn dropdown-toggle-split" ngbDropdownToggle [ngClass]="btn.class || 'btn-primary'"></button>
                        <div ngbDropdownMenu>
                            <button ngbDropdownItem *ngFor="let subcmd of btn.btns"
                                [disabled]="isDisabled(subcmd)" (click)="onClick(subcmd)">
                                <!-- [ngbTooltip]="tooltipContent" triggers="manual" #tool="ngbTooltip"
                                (mouseenter)="setTooltip(tool, btn)" (mouseleave)="tool.close()"> -->
                                <i class="far fa-fw fa-1x" [ngClass]="subcmd.icon"></i>&nbsp;{{subcmd.caption}}
                            </button>
                        </div>
                    </div>
                </div>

                <!--dropdown caret-->
                <div class="btn-group ms-2" role="group" [ngClass]="controlSize('btn-group')"
                    *ngIf="btn.type === 'cmd' && !btn.splitCaret && btn.btns && btn.btns.length > 0 && isVisible(btn)"
                    display="dynamic" placement="bottom-right" ngbDropdown>

                    <button type="button" class="btn" [ngClass]="btn.class || 'btn-primary'" [disabled]="isDisabled(btn)"
                        [attr.title]="btn.tooltip" placement="bottom" triggers="mouseenter" ngbDropdownToggle>
                        <i class="far fa-1x" [ngClass]="btn.icon"></i>
                        <span class="hidden-xs">&nbsp;{{btn.caption}}</span>
                    </button>
                    <div ngbDropdownMenu>
                        <button ngbDropdownItem *ngFor="let subcmd of btn.btns" [disabled]="isDisabled(subcmd)" (click)="onClick(subcmd)">
                            <!-- [ngbTooltip]="tooltipContent" triggers="manual" #tool="ngbTooltip"
                            (mouseenter)="setTooltip(tool, btn)" (mouseleave)="tool.close()"> -->
                            <i class="far fa-fw fa-1x" [ngClass]="subcmd.icon"></i>&nbsp;{{subcmd.caption}}
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</nav>