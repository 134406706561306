import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ControlsService, AppSettings, RouteBtn, StyleManager, RoutingService } from 'core';

@Component({
	selector: 'route-controls-container', templateUrl: './controls.container.html',
	host: { class: "d-flex flex-column", style: 'height:100%' },
})
export class ControlsContainer implements OnInit {
	constructor(public activeOffcanvas: NgbActiveOffcanvas,
		private http: HttpClient, private controls: ControlsService,
		private settings: AppSettings, private style: StyleManager,
		private routing: RoutingService
	) { }

	public buttons: any[] = [];

	ngOnInit() {
		this.buttons = this.controls.getRouteButtons();
	}

	controlSize(prefix: string, btn?: RouteBtn): string {
		var css = '';
		if (btn) { css += btn.class || 'btn-primary'; }
		if (this.style.css.controlsSize == 'sm') { css += ` ${prefix}-sm` }
		if (this.style.css.controlsSize == 'lg') { css += ` ${prefix}-lg` }
		return css
	}

	onClick(btn: RouteBtn) {
		if (/* btn.nivelAcces == 2 && */ this.routing.activeRoute && this.routing.activeRoute.onCommandClick) {
			this.routing.activeRoute.onCommandClick(btn);
			this.http.post('/log/client/command', {
				// uid: this.settings.idUnitate,
				// anLuna: this.settings.anLuna,
				// idUser: this.settings.idUser,
				hierarchyId: btn.hierarchyId,
				cod: btn.cod,
			}, { responseType: 'text' }).subscribe();
			this.activeOffcanvas.close();
		}
	}

	isVisible(btn: RouteBtn): boolean {
		if (this.routing.activeRoute && this.routing.activeRoute.isCommandVisible) {
			let visible = this.routing.activeRoute.isCommandVisible(btn);
			return visible === false ? false : true;
		} else return true;
	}

	isDisabled(btn: RouteBtn): boolean {
		if (/* btn.nivelAcces < 2 || */ this.http['isBusy']) { return true }
		if (!(this.routing.activeRoute && this.routing.activeRoute.isCommandEnabled)) { return false }
		const res = this.routing.activeRoute.isCommandEnabled(btn);
		const isString = typeof res == 'string';
		if (isString) {
			btn.tooltip = res
		} else if (btn.tooltip && res) {
			btn.tooltip = ''
		}
		return isString ? Boolean(res) : !res;
	}
}