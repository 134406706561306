import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbOffcanvas, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { mergeMap } from 'rxjs/operators';
import {
    UserService, ControlsService, RouteBtn,
    StyleManager, RoutingService, AppSettings
} from 'core';
import { ControlsContainer } from './controls.container';

@Component({
    selector: 'route-controls', templateUrl: './controls.html',
    styles: [
        '@import "bootswatch/dist/spacelab/bootstrap"',
        '@media only screen and (max-width: 320px) { .w-xs-80 { width: 80%; } }'
    ],
    // styles: ['$grid-breakpoints: (xs: 320px);']
})
export class NavbarControls implements OnInit {
    constructor(private offcanvasService: NgbOffcanvas, private router: Router,
        private http: HttpClient, private routing: RoutingService,
        private settings: AppSettings, protected user: UserService,
        protected controls: ControlsService, protected style: StyleManager,
    ) {
        routing.onRouteChange.pipe(
            mergeMap(route => route.data),
        ).subscribe(data => this.setSearchContext(data))
    }

    protected searchIcon: string;
    protected searchPlaceholder: string;
    protected isSearchHidden: boolean;
    private isSearchService: boolean;
    private searchPreserve: boolean;

    protected get isBusy(): boolean { return this.http['isBusy'] }
    protected getButtons() { return this.controls.getRouteButtons() }

    ngOnInit() { }

    protected controlSize(prefix: string, btn?: RouteBtn): string {
        var css = '';
        if (btn) { css += btn.class || 'btn-primary'; }
        if (this.style.css.controlsSize == 'sm') { css += ` ${prefix}-sm` }
        if (this.style.css.controlsSize == 'lg') { css += ` ${prefix}-lg` }
        return css
    }
    private setSearchContext(options) {
        this.isSearchHidden = options.isHidden || false;
        this.searchIcon = options.isSearch ? 'fa-search' : 'fa-filter';
        this.searchPlaceholder = options.placeholder || options.isSearch ? 'Cautare...' : 'Filtrare...';
        this.isSearchService = options.isAuto || false;
        this.searchPreserve = options.preserve || false;
    }
    protected onKeyPress(event) {
        // if (this.routing.activeRoute) {
        //     this.routing.activeRoute.searchText = this.controls.searchText || '';
        // }
        if (event.which == 13) { this.onSearch(event) }
    }
    protected onSearch(event) {
        event.preventDefault();
        if (this.isSearchService) {
            this.router.navigate([`/common/search/${encodeURIComponent(this.controls.searchText)}`])
        } else if (this.routing.activeRoute && this.routing.activeRoute.onSearch) {
            this.controls.keepSearch();
            this.routing.activeRoute.searchText = this.controls.searchText || '';
            this.routing.activeRoute.onSearch(this.controls.searchText || '');
        }
        return false
    }

    protected openBtnMenu() {
        this.offcanvasService.open(ControlsContainer, { position: 'end' });
    }

    private setTooltip(tooltip: NgbTooltip, btn: RouteBtn) {
        if (btn.tooltip) {
            tooltip.close();
            tooltip.open({ msg: btn.tooltip });
        }
    }

    protected onClick(btn: RouteBtn) {
        if (/* btn.nivelAcces == 2 && */ this.routing.activeRoute && this.routing.activeRoute.onCommandClick) {
            this.routing.activeRoute.onCommandClick(btn);
            // this.http.post('/log/client/command', {
            //     uid: this.settings.idUnitate,
            //     anLuna: this.settings.anLuna,
            //     idUser: this.settings.idUser,
            //     hierarchyId: btn.hierarchyId,
            //     cod: btn.cod,
            // }, { responseType: 'text' }).subscribe();
        }
    }

    protected isVisible(btn: RouteBtn): boolean {
        if (this.routing.activeRoute && this.routing.activeRoute.isCommandVisible) {
            let visible = this.routing.activeRoute.isCommandVisible(btn);
            return visible === false ? false : true;
        } else return true;
    }

    protected isDisabled(btn: RouteBtn): boolean {
        if (/* btn.nivelAcces < 2 || */ this.http['isBusy']) { return true }
        if (!(this.routing.activeRoute && this.routing.activeRoute.isCommandEnabled)) { return false }
        const res = this.routing.activeRoute.isCommandEnabled(btn);
        const isString = typeof res == 'string';
        if (isString) {
            btn.tooltip = res
        } else if (btn.tooltip && res) {
            btn.tooltip = ''
        }
        return isString ? Boolean(res) : !res;
    }
}